<template>
    <div>
      <b-card>
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
          <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
            <div class="d-flex justify-content-center align-items-center flex-column mr-5">
              <label for="voucherNoInput" class="text-nowrap h5 mr-5 p-50 mb-25">Voucher No:</label>
                <b-input-group class="mr-2">
                  <b-form-input
                    id="voucherNoFilter"
                    v-model="voucherNoFilter"
                    placeholder="Voucher No"
                  />
                </b-input-group>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column mr-5">
              <label for="customerCnicInput" class="text-nowrap h5 mr-5 p-50 mb-25">Customer CNIC:</label>
                <b-input-group class="mr-2">
                  <b-form-input
                    id="customerCnicFilter"
                    v-model="customerCnicFilter"
                    placeholder="Customer cnic"
                  />
                </b-input-group>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column mr-5">
              <label for="guestCnicInput" class="text-nowrap h5 mr-5 p-50 mb-25">Guest CNIC:</label>
                <b-input-group class="mr-2">
                  <b-form-input
                    id="guestCnicFilter"
                    v-model="guestCnicFilter"
                    placeholder="Guest cnic"
                  />
                </b-input-group>
            </div>
            <template v-if="!messSecretary && !messReceptionist">
              <div class="d-flex justify-content-center align-items-center flex-column mr-5">
                <label for="city" class="text-nowrap h5 mr-5 p-50 mb-25">City:</label>
                  <b-input-group class="mr-2">
                    <v-select id="city" inputId="id" label="name" v-model="selectedCity" :options="cities"
                    class="w-100" :disabled="cityDisabled"/>
                  </b-input-group>
              </div>
              <div class="d-flex justify-content-center align-items-center flex-column">
                <label for="city" class="text-nowrap h5 mr-5 p-50 mb-25">Mess:</label>
                  <b-input-group class="mr-2">
                    <v-select id="mess" inputId="id" label="name" v-model="selectedMess" :options="messes"
                    class="w-100"/>
                  </b-input-group>
              </div>
            </template>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
          <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1" >
            <div class="d-flex justify-content-center align-items-center flex-column">
              <label for="creationDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Creation Date From:</label>
               <b-input-group class="mr-2">
                <b-form-input
                  id="fromDateFilter"
                  v-model="fromDateFilter"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="on"
                ></b-form-input>
               <b-input-group-append>
                  <b-form-datepicker
                    id="fromDateFilterDatePicker"
                    v-model="fromDateFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                  />
              </b-input-group-append>
            </b-input-group>
          </div>
        <div class="d-flex justify-content-center align-items-center flex-column mr-5">
           <label for="creationDateToInput" class="text-nowrap h5 mr-5 p-50 mb-25">Creation Date To:</label>
            <b-input-group>
              <b-form-input
                id="toDateFilter"
                v-model="toDateFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  id="toDateFilterDatePicker"
                  v-model="toDateFilter"
                  button-variant="primary"
                  button-only
                  variant="primary"
                  style="height: 38px"
                  right
                />
              </b-input-group-append>
            </b-input-group>
            </div>
          <div class="d-flex justify-content-center align-items-center flex-column" >
           <label for="paymentDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Payment Date From:</label>
            <b-input-group class="ml-2">
                <b-form-input
                id="paymentDateFromFilter"
                v-model="paymentDateFromFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="paymentDateFromFilterDatePicker"
                    v-model="paymentDateFromFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
            </b-input-group>
          </div>
        <div class="d-flex justify-content-center align-items-center flex-column">
          <label for="paymentDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Payment Date To:</label>
            <b-input-group class="ml-3">
                <b-form-input
                id="paymentDateToFilter"
                v-model="paymentDateToFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="paymentDateToFilterDatePicker"
                    v-model="paymentDateToFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
        <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
         <div class="d-flex justify-content-center align-items-center flex-column">
          <label for="checkinDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Check In Clicked <br/> Date From:</label>
            <b-input-group class="mr-2">
                <b-form-input
                id="checkinDateFromFilter"
                v-model="checkinDateFromFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="checkinDateFromFilterDatePicker"
                    v-model="checkinDateFromFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
            </b-input-group>
         </div>
          <div class="d-flex justify-content-center align-items-center flex-column mr-5">
           <label for="checkinDateToInput" class="text-nowrap h5 mr-5 p-50 mb-25">Check In Clicked <br/> Date To:</label>
            <b-input-group>
                <b-form-input
                id="checkinDateToFilter"
                v-model="checkinDateToFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="checkinDateToFilterDatePicker"
                    v-model="checkinDateToFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
            </b-input-group>
          </div>
          <div class="d-flex justify-content-center align-items-center flex-column">
           <label for="checkoutDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Check Out Clicked <br/> Date From:</label>
            <b-input-group class="ml-2">
                <b-form-input
                id="checkoutDateFromFilter"
                v-model="checkoutDateFromFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="checkoutDateFromFilterDatePicker"
                    v-model="checkoutDateFromFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
            </b-input-group>
            </div>
          <div class="d-flex justify-content-center align-items-center flex-column" >
           <label for="checkoutDateToInput" class="text-nowrap h5 mr-5 p-50 mb-25">Check Out Clicked <br/> Date To:</label>
            <b-input-group class="ml-3">
                <b-form-input
                id="checkoutDateToFilter"
                v-model="checkoutDateToFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="checkoutDateToFilterDatePicker"
                    v-model="checkoutDateToFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
            </b-input-group>
            </div>
        </div>
        </div>
      <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
       <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
        <div class="d-flex justify-content-center align-items-center flex-column">
          <label for="refundDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Refund Date From:</label>
           <b-input-group class="mr-1">
                <b-form-input
                id="refundDateFromFilter"
                v-model="refundDateFromFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="refundDateFromFilterDatePicker"
                    v-model="refundDateFromFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
             </b-input-group>
           </div>
          <div class="d-flex justify-content-center align-items-center flex-column" >
           <label for="refundDateToFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Refund Date To:</label>
            <b-input-group class="ml-1">
                <b-form-input
                id="refundDateToFilter"
                v-model="refundDateToFilter"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="on"
                ></b-form-input>
                <b-input-group-append>
                <b-form-datepicker
                    id="refundDateToFilterDatePicker"
                    v-model="refundDateToFilter"
                    button-variant="primary"
                    button-only
                    variant="primary"
                    style="height: 38px"
                    right
                />
                </b-input-group-append>
            </b-input-group>
           </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
          <div class="d-flex justify-content-center align-items-center flex-nowrap mr-2">
            <b-button variant="primary" pill @click="search">
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span class="align-middle">Search</span>
            </b-button>
          </div>
          <div
            class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
          >
            <b-button variant="primary" pill @click="search">
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span class="align-middle">Refresh</span>
            </b-button>
          </div>
          <div
            class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
          >
            <b-button variant="primary" pill @click="downloadPDF">
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span class="align-middle">Download PDF</span>
            </b-button>
          </div>
          <!-- <div class="d-flex justify-content-center align-items-center flex-nowrap mr-2">
              <b-button variant="primary" pill @click="downloadXLS">
                <feather-icon icon="DownloadIcon" class="mr-50" />
                <span class="align-middle">Download Excel Report</span>
              </b-button>
            </div> -->
          <div
            class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
          >
            <b-button variant="primary" pill @click="downloadPaymentsXLS">
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span class="align-middle">Download Payments Excel Report</span>
            </b-button>
          </div>
        </div>
        <template v-if="isBusy">
          <div class="text-center text-danger my-2">
            <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-else>
          <b-table-simple
            id="pdfTable"
            hover
            bordered
            small
            caption-top
            responsive
            class="text-center"
          >
            <b-thead>
              <b-tr>
                <b-th colspan="1">Ser</b-th>
                <b-th colspan="1">Voucher Number</b-th>
                <b-th colspan="1">Customer Cnic</b-th>
                <b-th colspan="1">Guest Cnic</b-th>
                <b-th colspan="1">Customer Details</b-th>
                <b-th colspan="1">Customer Bank Account Details</b-th>
                <b-th colspan="1">Start Date</b-th>
                <b-th colspan="1">End Date</b-th>
                <b-th colspan="1">Rooms</b-th>
                <b-th colspan="1">Total Payment</b-th>
                <b-th colspan="1">Refund Amount</b-th>
                <b-th colspan="1">Refund Date</b-th>
                <!-- <b-th colspan="1">AGM Share</b-th>
                  <b-th colspan="1">Mess Share</b-th> -->
                <b-th colspan="1">Mess</b-th>
                <!-- <b-th colspan="1">Mess Iban</b-th> -->
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(row, index) in data" :key="index">
                <b-th>
                  {{ parseInt(index) + 1 }}
                </b-th>
                <b-td class="text-center"> 786786{{ row.voucher_no }} </b-td>
                <b-td class="text-center">
                  {{ row.customer.cnic }}
                </b-td>
                <b-td class="text-center">
                  {{ row.guest ? row.guest.cnic : "" }}
                </b-td>
                <b-td class="text-center">
                  Cnic: {{ row.customer.cnic }} <br />
                  Name: {{ row.customer.name }} <br />
                  Mobile: {{ row.customer.mobile }}
                </b-td>
                <b-td class="text-center">
                  Bank:
                  {{
                    row.refund_account
                      ? row.refund_account.bank_name
                        ? row.refund_account.bank_name
                        : ""
                      : ""
                  }}
                  <br />
                  Account Title:
                  {{
                    row.refund_account
                      ? row.refund_account.account_title
                        ? row.refund_account.account_title
                        : ""
                      : ""
                  }}
                  <br />
                  Account Number/IBAN:
                  {{
                    row.refund_account
                      ? row.refund_account.account_number
                        ? row.refund_account.account_number
                        : ""
                      : ""
                  }}
                  {{
                    row.refund_account
                      ? row.refund_account.iban
                        ? row.refund_account.iban
                        : ""
                      : ""
                  }}
                </b-td>
                <b-td class="text-center">
                  {{ row.start_date_time }}
                </b-td>
                <b-td class="text-center">
                  {{ row.end_date_time }}
                </b-td>
                <b-td class="text-center">
                  {{ row.rooms.length }}
                </b-td>
                <b-td class="text-center">
                  {{ row.total_payment }}
                </b-td>
                <b-td class="text-center">
                  {{ row.refund_amount }}
                </b-td>
                <b-td class="text-center">
                  {{ row.guest_refund_date_time }}
                </b-td>
                <!-- <b-td class="text-center">
                    {{ row.agm_share }}
                  </b-td>
                  <b-td class="text-center">
                    {{ row.mess_share }}
                  </b-td> -->
                <b-td class="text-center">
                  {{ row.mess_data ? row.mess_data.name : null }}
                </b-td>
                <!-- <b-td class="text-center">
                    {{ row.mess_data ? row.mess_data.iban : null }}
                  </b-td> -->
              </b-tr>
            </b-tbody>
            <b-tfoot>
            </b-tfoot>
          </b-table-simple>
          <b-row>
            <b-col md="2">
              <div style="float: left">
                <h5 style="margin: 0; display: inline" class="text-primary">
                  Count:
                </h5>
                <h5 style="margin: 0; display: inline" align="right">
                  <strong>
                    {{ totalDataLength }}
                  </strong>
                </h5>
              </div>
            </b-col>
          </b-row>
        </template>
      </b-card>
      <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </div>
  </template>
    
<script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import moment from "moment";
  import html2canvas from 'html2canvas';
  import jsPDF from 'jspdf';
  
  export default {
    mixins: [util],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        data: [],
        show: false,
        voucherNoFilter: '',
        customerCnicFilter: '',
        guestCnicFilter: '',
        fromDateFilter: "",
        toDateFilter: "",
        paymentDateToFilter:'',
        paymentDateFromFilter:'',
        refundDateToFilter:'',
        refundDateFromFilter:'',
        checkinDateFromFilter: '',
        checkinDateToFilter: '',
        checkoutDateFromFilter: '',
        checkoutDateToFilter: '',
        isBusy: false,
        exportXls: false,
        totalDataLength: 0,
        mess: null,
        managingHq: null,
        excelFile: null,
        messes: [],
        selectedMess: null,
        cities: [],
        selectedCity: null,
        cityDisabled: false,
        messSecretary: false,
        messReceptionist: false,
      };
    },
    created() {},
    async mounted() {
      let res = await this.getBookingCitiesUnpaginated({
        mess_only: true,
      });
      this.cities = res.data
      res = await this.getMessesUnpaginated({ })
      this.messes = res.data
      //await this.search();
    },
    methods: {
      ...mapActions({
        getEarlyCheckoutGuestReport: "appData/getEarlyCheckoutGuestReport",
        getEarlyCheckoutGuestReportExcel:
          "appData/getEarlyCheckoutGuestReportExcel",
        //   getEarlyCheckoutGuestRecordExcel:
        //   "appData/getEarlyCheckoutGuestRecordExcel",
        earlyCheckoutRefundBookingsFromExcel: "appData/earlyCheckoutRefundBookingsFromExcel",
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
      }),
      async updateMess() {
        try {
          this.selectedMess = null
          let res = await this.getMessesUnpaginated({ city: this.selectedCity.id })
          this.messes = res.data
        }
        catch (error) {
          // this.displayError
        }
      },
      async search() {
        try {
          this.isBusy = true;
          const res = await this.getEarlyCheckoutGuestReport({
            mess: this.mess,
            city: this.selectedCity ? this.selectedCity.id : null,
            voucher_no: this.voucherNoFilter,
            customer_cnic: this.customerCnicFilter,
            guest_cnic: this.guestCnicFilter,
            date_from: this.fromDateFilter,
            date_to: this.toDateFilter,
            payment_date_from: this.paymentDateFromFilter,
            payment_date_to: this.paymentDateToFilter,
            refund_date_from: this.refundDateFromFilter,
            refund_date_to: this.refundDateToFilter,
            check_in_date_from: this.checkinDateFromFilter,
            check_in_date_to: this.checkinDateToFilter,
            check_out_date_from: this.checkoutDateFromFilter,
            check_out_date_to: this.checkoutDateToFilter,
            refunded: true,
          });
          this.data = res.data;
          this.totalDataLength = this.data.length;
          this.isBusy = false;
        } catch (error) {}
      },
      async downloadPDF() {
        try {
            this.show = true;
            await this.search();

            await new Promise((resolve) => setTimeout(resolve, 1000));
            var currentdate = new Date();
            const filename = "Early Checkout Guest Refunds (Refunded) "+ currentdate.getDate() + "-" + + (currentdate.getMonth()+1)  + "-" + currentdate.getFullYear() + " --- " + currentdate.getHours() + "-" + currentdate.getMinutes() + "-" + currentdate.getSeconds()+".pdf";
            const pdfTable = document.getElementById("pdfTable");
            html2canvas(pdfTable).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 0, 0, imgWidth, imgHeight);
            pdf.save(filename);
            });
            this.show = false;
        } catch (error) {
            console.error("Error generating PDF:", error);
        }
      },
  
    //     this.show = true;
    //     await this.search();
    //     this.exportXls = true;
    //     let payload = {
    //       ...this.filter,
    //     };
  
    //     payload["refunded"] = true;
    //     if (this.fromDateFilter) {
    //       payload["date_from"] = this.fromDateFilter;
    //     }
    //     if (this.toDateFilter) {
    //       payload["date_to"] = this.toDateFilter;
    //     }
    //     if (this.mess) {
    //       payload["mess"] = this.mess;
    //     }
    //     if (this.managingHq) {
    //       payload["managing_hq"] = this.managingHq;
    //     }
  
    //     try {
    //       const res = await this.getEarlyCheckoutGuestRecordExcel({
    //         payload: payload,
    //       });
    //       if (res.status === 200) {
    //         var currentdate = new Date();
    //         const url = URL.createObjectURL(res.data);
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute(
    //           "download",
    //           "Early Checkout Guest Refunds Refunded (Record) " +
    //             currentdate.getDate() +
    //             "-" +
    //             +(currentdate.getMonth() + 1) +
    //             "-" +
    //             currentdate.getFullYear() +
    //             " --- " +
    //             currentdate.getHours() +
    //             "-" +
    //             currentdate.getMinutes() +
    //             "-" +
    //             currentdate.getSeconds() +
    //             ".xlsx"
    //         );
    //         document.body.appendChild(link);
    //         link.click();
    //         this.$swal({
    //           title: "Download successful",
    //           icon: "success",
    //           timer: 1500,
    //         });
    //       }
    //       if (res.status === 204) {
    //         this.$swal({
    //           title: "No Record Found",
    //           icon: "info",
    //         });
    //       }
    //       this.show = false;
    //     } catch (error) {
    //       this.show = false;
    //       this.displayError(error);
    //     }
    //   },
  
      async downloadPaymentsXLS() {
        this.show = true;
        // await this.search();
        this.exportXls = true;
        let payload = {
          ...this.filter,
        };
  
        payload["refunded"] = true;
        if (this.voucherNoFilter) {
          payload["voucher_no"] = this.voucherNoFilter;
        }
        if (this.customerCnicFilter) {
          payload["customer_cnic"] = this.customerCnicFilter;
        }
        if (this.guestCnicFilter) {
          payload["guest_cnic"] = this.guestCnicFilter;
        }
        if (this.fromDateFilter) {
          payload["date_from"] = this.fromDateFilter;
        }
        if (this.toDateFilter) {
          payload["date_to"] = this.toDateFilter;
        }
        if (this.paymentDateFromFilter) {
          payload["payment_date_from"] = this.paymentDateFromFilter;
        }
        if (this.paymentDateToFilter) {
          payload["payment_date_to"] = this.paymentDateToFilter;
        }
        if (this.refundDateFromFilter) {
          payload["refund_date_from"] = this.refundDateFromFilter;
        }
        if (this.refundDateToFilter) {
          payload["refund_date_to"] = this.refundDateToFilter;
        }
        if (this.checkinDateFromFilter) {
          payload["check_in_date_from"] = this.checkinDateFromFilter;
        }
        if (this.checkinDateToFilter) {
          payload["check_in_date_to"] = this.checkinDateToFilter;
        }
        if (this.checkoutDateFromFilter) {
          payload["check_out_date_from"] = this.checkoutDateFromFilter;
        }
        if (this.checkoutDateToFilter) {
          payload["check_out_date_to"] = this.checkoutDateToFilter;
        }
        if (this.mess) {
          payload["mess"] = this.mess;
        }
        if (this.managingHq) {
          payload["managing_hq"] = this.managingHq;
        }
        if (this.selectedCity) {
          payload["city"] = this.selectedCity.id;
        }
  
        try {
          const res = await this.getEarlyCheckoutGuestReportExcel({
            payload: payload,
          });
          if (res.status === 200) {
            var currentdate = new Date();
            const url = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Early Checkout Guest Refunds (Refunded) " +
                currentdate.getDate() +
                "-" +
                +(currentdate.getMonth() + 1) +
                "-" +
                currentdate.getFullYear() +
                " --- " +
                currentdate.getHours() +
                "-" +
                currentdate.getMinutes() +
                "-" +
                currentdate.getSeconds() +
                ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
            this.$swal({
              title: "Download successful",
              icon: "success",
              timer: 1500,
            });
          }
          if (res.status === 204) {
            this.$swal({
              title: "No Record Found",
              icon: "info",
            });
          }
          this.show = false;
        } catch (error) {
          this.show = false;
          this.displayError(error);
        }
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getUser",
      }),
    },
    watch: {
      async selectedCity(newValue, oldValue) {
        try {
          if (newValue) {
            this.messes = []
            await this.updateMess();
          } else {
            this.messes = []
          }
        } catch (error) {
          // console.error(error);
        }
      },
      selectedMess(newValue, oldValue) {
        if (newValue) {
          this.mess = this.selectedMess.id
          this.cityDisabled = true
        }
        else{
          this.mess = null,
          this.cityDisabled = false
        }
      },
    },
  };
  </script>
    
    <style>
  .border-dark {
    border: black solid 1px !important;
  }
  .custom-file-input {
      height: 50px; /* Adjust height as needed */
      font-size: 16px; /* Adjust font size as needed */
      /* Add any additional custom styles */
  }
  </style>